import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // initial State
  insights: {
    visitors: 0,
    actions: 0,
    views: 0,
    bounceRate: 0,
    tag_actions: 0
  },
  devicesData: { labels: [], dataset1: [], dataset2: [] },
  browsersData: { labels: [], dataset1: [], dataset2: [] },
  countriesData: { labels: [], dataset1: [], dataset2: [] },
  pageUrlData: { labels: [], dataset1: [], dataset2: [] },
  personaData: {
    labels: [],
    views: [],
    visitors: [],
    secondViews: [],
    secondVisitors: []
  }, // 4 data
  viewsVisitorsData: {
    labels: [],
    views: [],
    visitors: [],
    secondViews: [],
    secondVisitors: []
  }, // 4 data
  worldData: { labels: [], dataset1: [], dataset2: [], visitors: [] },
  ageGroupData: { labels: [], dataset: [] },
  sourceData: {},
  mediumData: {},
  campaignData: {},
  termData: {},
  contentData: {},
  sourceLinkData: {},
  isLoading: true
};

const compareSlice = createSlice({
  name: "compareData",
  initialState,
  reducers: {
    setCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    setCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    setCompareDevicesData: (state, actions) => {
      return { ...state, devicesData: { ...actions.payload } };
    },
    setCompareBrowsersData: (state, actions) => {
      return { ...state, browsersData: { ...actions.payload } };
    },
    setCompareCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    setComparePageUrlData: (state, actions) => {
      return { ...state, pageUrlData: { ...actions.payload } };
    },
    setComparePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    setCompareViewsVisitorsData: (state, actions) => {
      return { ...state, viewsVisitorsData: { ...actions.payload } };
    },
    setCompareWorldData: (state, actions) => {
      return { ...state, worldData: { ...actions.payload } };
    },
    setCompareAgeGroupData: (state, actions) => {
      return { ...state, ageGroupData: { ...actions.payload } };
    },
    setCompareSourceData: (state, actions) => {
      return { ...state, sourceData: { ...actions.payload } };
    },
    setCompareMediumData: (state, actions) => {
      return { ...state, mediumData: { ...actions.payload } };
    },
    setCompareCampaignData: (state, actions) => {
      return { ...state, campaignData: { ...actions.payload } };
    },
    setCompareTermData: (state, actions) => {
      return { ...state, termData: { ...actions.payload } };
    },
    setCompareContentData: (state, actions) => {
      return { ...state, contentData: { ...actions.payload } };
    },
    setCompareSourceLinkData: (state, actions) => {
      return { ...state, sourceLinkData: { ...actions.payload } };
    }
  }
});

export const {
  setCompareDevicesData,
  setCompareBrowsersData,
  setCompareCountriesData,
  setComparePageUrlData,
  setComparePersonaData,
  setCompareViewsVisitorsData,
  setCompareWorldData,
  setCompareAgeGroupData,
  setCompareFilteredData,
  setCompareFiltersList,
  setCompareInsights,
  setCompareIsLoading,
  setCompareSourceData,
  setCompareMediumData,
  setCompareCampaignData,
  setCompareTermData,
  setCompareContentData,
  setCompareSourceLinkData
} = compareSlice.actions;

export default compareSlice.reducer;
