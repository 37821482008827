/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import StackedChart from "../../../components/ChartComponents/StackedChart";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import LineChart from "../DashBoardLandingPage/LineChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import { findPersonaDescription } from "../../../utils/findPersonaDescription";
import PersonaLabel from "../../../components/PersonaLabel";
import Insights from "../../../components/Insights";
import LimitExceeded from "../../Placeholders/LimitExceeded";

export default function CompareDashboard({
  isCompare,
  selectedFilters,
  insightsTitle,
  dashboard,
  updateSelectedFilters
}) {
  const {
    browsersData,
    devicesData,
    countriesData,
    pageUrlData,
    viewsVisitorsData,
    worldData,
    personaData,
    sourceData,
    mediumData,
    campaignData,
    termData,
    contentData,
    sourceLinkData
  } = useSelector((state) => state.compareData);

  const {
    allData: allData2,
    insights: insightsToCompare,
    pageURLGraphData,
    graphSepData,
    limitExceeded
  } = useSelector((state) => state.dataToCompare); // compareData
  const { allData: allData1, insights } = useSelector(
    (state) => state.dashboardData
  );
  const { t } = useTranslation();

  const [limitedFilteredData, setLimitedFilteredData] = useState([]);

  useEffect(() => {
    setLimitedFilteredData(pageUrlData);
  }, [pageUrlData]);

  const handleSearch = (searchTerm) => {
    // Check if the search term is provided
    if (!searchTerm) {
      setLimitedFilteredData(pageUrlData); // Reset to original data if no search term
      return;
    }

    const query = searchTerm?.toLowerCase();
    const {
      bounce,
      bounce1,
      bounce2,
      dataset1,
      dataset2,
      labels,
      links,
      preSales,
      preSales1,
      preSales2,
      revenue,
      revenue1,
      revenue2,
      sales,
      sales1,
      sales2,
      views,
      views1,
      views2,
      visitors
    } = pageUrlData;

    // Filter indices where the labels match the search string
    const filteredIndices = labels
      .map((label, index) => {
        if (label.toLowerCase().includes(query)) {
          return index;
        }
        return -1;
      })
      .filter((index) => index !== -1);

    // If no matches found, show everything
    if (filteredIndices?.length === 0) {
      setLimitedFilteredData(pageUrlData);
      return;
    }

    // Create a new object with filtered arrays
    const filteredData = {
      bounce: filteredIndices.map((index) => bounce[index]),
      bounce1: filteredIndices.map((index) => bounce1[index]),
      bounce2: filteredIndices.map((index) => bounce2[index]),
      dataset1: filteredIndices.map((index) => dataset1[index]),
      dataset2: filteredIndices.map((index) => dataset2[index]),
      labels: filteredIndices.map((index) => labels[index]),
      links: filteredIndices.map((index) => links[index]),
      preSales: filteredIndices.map((index) => preSales[index]),
      preSales1: filteredIndices.map((index) => preSales1[index]),
      preSales2: filteredIndices.map((index) => preSales2[index]),
      revenue: filteredIndices.map((index) => revenue[index]),
      revenue1: filteredIndices.map((index) => revenue1[index]),
      revenue2: filteredIndices.map((index) => revenue2[index]),
      sales: filteredIndices.map((index) => sales[index]),
      sales1: filteredIndices.map((index) => sales1[index]),
      sales2: filteredIndices.map((index) => sales2[index]),
      views: filteredIndices.map((index) => views[index]),
      views1: filteredIndices.map((index) => views1[index]),
      views2: filteredIndices.map((index) => views2[index]),
      visitors: filteredIndices.map((index) => visitors[index])
    };

    setLimitedFilteredData(filteredData);
  };

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedFilters
  };

  const personaDescription = findPersonaDescription(
    allData1,
    commonProps.filterValues,
    t
  );

  if (limitExceeded) return <LimitExceeded />;

  if (allData1.length > 0 && allData2.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          insightsToCompare={insightsToCompare}
        />
        <StackedChart
          title={t("analyticsPage.interests")}
          views={personaData.views}
          visitors={personaData.visitors}
          secondViews={personaData.secondViews}
          secondVisitors={personaData.secondVisitors}
          labels={personaData.labels}
          updatedComponentFilters={updateSelectedFilters}
          selectedFilters={selectedFilters}
        />
        {/* <PersonaLabel personaDescription={personaDescription} /> */}
        <div
          className="grid grid-template"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.devicesTitle")}
            labels={devicesData.labels}
            numBars={
              devicesData?.labels?.length < 6 ? devicesData?.labels?.length : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: devicesData.dataset1
            }}
            compareData={devicesData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: devicesData?.visitors,
                first: devicesData?.dataset1,
                second: devicesData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            labels={browsersData.labels}
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.browsersTitle")}
            visitors={browsersData.visitors}
            numBars={
              browsersData?.labels?.length < 6
                ? browsersData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: browsersData.dataset1
            }}
            compareData={browsersData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: browsersData?.visitors,
                first: browsersData?.dataset1,
                second: browsersData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            labels={countriesData.labels}
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.countryTitle")}
            numBars={
              countriesData?.labels?.length < 6
                ? countriesData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: countriesData.dataset1
            }}
            compareData={countriesData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: countriesData?.visitors,
                first: countriesData?.dataset1,
                second: countriesData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div className="grid">
          <WorldMap
            data={worldData.dataset1}
            compareData={worldData.dataset2}
            diffData={worldData.visitors}
            labels={worldData.labels}
            title={t("analyticsPage.worldTitle")}
            updateFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
          />
        </div>
        <LineChart
          views={viewsVisitorsData.views}
          visitors={viewsVisitorsData.visitors}
          secondViews={viewsVisitorsData.secondViews}
          secondVisitors={viewsVisitorsData.secondVisitors}
          labels={viewsVisitorsData.labels}
          title={t("analyticsPage.visitorsPageViewsTitle")}
        />

        <div className="grid half-column-grid">
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={sourceData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.source")}
              numBars={
                sourceData?.labels?.length < 6 ? sourceData?.labels?.length : 4
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: sourceData.dataset1
              }}
              compareData={sourceData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: sourceData?.visitors,
                  first: sourceData?.dataset1,
                  second: sourceData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={mediumData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.medium")}
              numBars={
                mediumData?.labels?.length < 6 ? mediumData?.labels?.length : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: mediumData.dataset1
              }}
              compareData={mediumData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: mediumData?.visitors,
                  first: mediumData?.dataset1,
                  second: mediumData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
        </div>
        <div className="grid half-column-grid">
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={campaignData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.campaign")}
              numBars={
                campaignData?.labels?.length < 6
                  ? campaignData?.labels?.length
                  : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: campaignData.dataset1
              }}
              compareData={campaignData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: campaignData?.visitors,
                  first: campaignData?.dataset1,
                  second: campaignData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={termData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.term")}
              numBars={
                termData?.labels?.length < 6 ? termData?.labels?.length : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: termData.dataset1
              }}
              compareData={termData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: termData?.visitors,
                  first: termData?.dataset1,
                  second: termData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
        </div>
        <div className="grid half-column-grid">
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={contentData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.content")}
              numBars={
                contentData?.labels?.length < 6
                  ? contentData?.labels?.length
                  : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: contentData.dataset1
              }}
              compareData={contentData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: contentData?.visitors,
                  first: contentData?.dataset1,
                  second: contentData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={sourceLinkData?.labels}
              loading={graphSepData?.isLoading}
              title={t("analyticsPage.sourceLink")}
              numBars={
                sourceLinkData?.labels?.length < 6
                  ? sourceLinkData?.labels?.length
                  : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: sourceLinkData.dataset1
              }}
              compareData={sourceLinkData.dataset2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: sourceLinkData?.visitors,
                  first: sourceLinkData?.dataset1,
                  second: sourceLinkData?.dataset2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
        </div>

        <AddBarGraph
          loading={pageURLGraphData?.isLoading}
          limitedFilteredData={limitedFilteredData}
          setLimitedFilteredData={setLimitedFilteredData}
          search={true}
          handleSearch={handleSearch}
          pageUrlDataNew={pageUrlData}
          // labels={pageUrlData.labels}
          labels={limitedFilteredData?.labels}
          // links={pageUrlData?.links}
          links={limitedFilteredData?.links}
          title={t("analyticsPage.pagesTitle")}
          data={{
            tooltip: t("analyticsPage.visitors"),
            values: limitedFilteredData.dataset1
            // values: pageUrlData.dataset1
          }}
          // compareData={pageUrlData.dataset2}
          compareData={limitedFilteredData.dataset2}
          // numBars={
          //   pageUrlData?.labels?.length < 25 ? pageUrlData?.labels?.length : 25
          // }
          numBars={
            limitedFilteredData?.labels?.length < 25
              ? limitedFilteredData?.labels?.length
              : 25
          }
          columnData={[
            {
              id: 1,
              name: t("analyticsPage.visitors"),
              // diff: pageUrlData?.visitors,
              // first: pageUrlData?.dataset1,
              // second: pageUrlData?.dataset2,
              diff: limitedFilteredData?.visitors,
              first: limitedFilteredData?.dataset1,
              second: limitedFilteredData?.dataset2,
              number: true
            },
            {
              id: 2,
              name: t("analyticsPage.views"),
              // diff: pageUrlData?.views,
              // first: pageUrlData?.views1,
              // second: pageUrlData?.views2,
              diff: limitedFilteredData?.views,
              first: limitedFilteredData?.views1,
              second: limitedFilteredData?.views2,
              number: true
            },
            {
              id: 3,
              name: t("analyticsPage.bounce"),
              // diff: pageUrlData?.bounce,
              // first: pageUrlData?.bounce1,
              // second: pageUrlData?.bounce2,
              diff: limitedFilteredData?.bounce,
              first: limitedFilteredData?.bounce1,
              second: limitedFilteredData?.bounce2,
              percent: true
            },
            {
              id: 4,
              name: t("analyticsPage.preSalesEvent"),
              // diff: pageUrlData?.preSales,
              // first: pageUrlData?.preSales1,
              // second: pageUrlData?.preSales2,
              diff: limitedFilteredData?.preSales,
              first: limitedFilteredData?.preSales1,
              second: limitedFilteredData?.preSales2,
              number: true
            },
            {
              id: 5,
              name: t("analyticsPage.salesEvent"),
              // diff: pageUrlData?.sales,
              // first: pageUrlData?.sales1,
              // second: pageUrlData?.sales2,
              diff: limitedFilteredData?.sales,
              first: limitedFilteredData?.sales1,
              second: limitedFilteredData?.sales2,
              number: true
            },
            {
              id: 6,
              name: t("analyticsPage.revenue"),
              // diff: pageUrlData?.revenue,
              // first: pageUrlData?.revenue1,
              // second: pageUrlData?.revenue2,
              diff: limitedFilteredData?.revenue,
              first: limitedFilteredData?.revenue1,
              second: limitedFilteredData?.revenue2,
              euro: true
            }
          ]}
          {...commonProps}
        />
      </>
    );

  return <NoDataFound />;
}

CompareDashboard.propTypes = {
  isCompare: PropTypes.bool,
  selectedFilters: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  updateSelectedFilters: PropTypes.func
};
